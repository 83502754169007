@font-face {
    font-family: 'Rounded Mplus 1c';
    src: url('/fonts/Roundedmplus/Roundedmplus1clight.woff2') format('woff2'), url('/fonts/Roundedmplus/Roundedmplus1clight.woff') format('woff'), url('/fonts/Roundedmplus/Roundedmplus1clight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rounded Mplus 1c';
    src: url('/fonts/Roundedmplus/Roundedmplus1c.woff2') format('woff2'), url('/fonts/Roundedmplus/Roundedmplus1c.woff') format('woff'), url('/fonts/Roundedmplus/Roundedmplus1c.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rounded Mplus 1c';
    src: url('/fonts/Roundedmplus/Roundedmplus1cthin.woff2') format('woff2'), url('/fonts/Roundedmplus/Roundedmplus1cthin.woff') format('woff'), url('/fonts/Roundedmplus/Roundedmplus1cthin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rounded Mplus 1c';
    src: url('/fonts/Roundedmplus/Roundedmplus1cextrabold.woff2') format('woff2'), url('/fonts/Roundedmplus/Roundedmplus1cextrabold.woff') format('woff'), url('/fonts/Roundedmplus/Roundedmplus1cextrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rounded Mplus 1c';
    src: url('/fonts/Roundedmplus/Roundedmplus1cblack.woff2') format('woff2'), url('/fonts/Roundedmplus/Roundedmplus1cblack.woff') format('woff'), url('/fonts/Roundedmplus/Roundedmplus1cblack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rounded Mplus 1c';
    src: url('/fonts/Roundedmplus/Roundedmplus1cmedium.woff2') format('woff2'), url('/fonts/Roundedmplus/Roundedmplus1cmedium.woff') format('woff'), url('/fonts/Roundedmplus/Roundedmplus1cmedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rounded Mplus 1c';
    src: url('/fonts/Roundedmplus/Roundedmplus1cbold.woff2') format('woff2'), url('/fonts/Roundedmplus/Roundedmplus1cbold.woff') format('woff'), url('/fonts/Roundedmplus/Roundedmplus1cbold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}