* {
    -webkit-tap-highlight-color: transparent;
}

.disabled-overlay {
    z-index: 9;
    position: fixed;
    top: 0;
    background-color: rgba(32, 32, 35, 0.1);
    width: 100%;
    height: 100%;
}

p.error {
    color: #c41d7f !important;
    font-size: 14px !important;
    text-align: start !important;
    padding: 5px 0 !important;
}
